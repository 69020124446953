import React from "react"
import { StoreContext } from "../../../context/store-context"

import {AddToCartBtn} from './addToCart.styles'

import { Icon } from '@iconify/react';

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  if (available) {
  return (
    <AddToCartBtn type="submit" onClick={addToCart} disabled={!available || loading} {...props} >
      <Icon icon="bi:cart-plus-fill" height="24px" width="24px" className="me-3" /> Add to Cart
    </AddToCartBtn>
  )} else{
    return(
    <AddToCartBtn type="submit" onClick={addToCart} disabled={!available || loading} {...props} >
      <Icon icon="bi:bag-x-fill" height="24px" width="24px" className="me-3" /> Out of Stock
    </AddToCartBtn>
  )}
}
