import styled from 'styled-components'
import { Container, Col, Button, Form } from 'react-bootstrap'
import { Link } from 'gatsby'
import GatsbyLink from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'

export const ProductContainer = styled(Container)`
    font-family: ${({theme}) => theme.fonts.font1};
    max-width: 100vw;

`
export const GalleryContainer = styled.div`
    width: auto;
    min-width: 0;

    @media only screen and (max-width: 400px){
        display: flex;
        flex-direction: column;
    }
`

export const GatsbyImg = styled(GatsbyImage)`
    border-radius: 15px;
    width: 1fr;

    img{
        height: auto;
    }
`
export const GatsbyThumbsImg = styled(GatsbyImage)`
    // margin-top: 1em;
    margin: 1em 1em 1em 0em;
    border-radius: 10px;
    height: auto;
    width: 100px;
`
export const LinkStyled = styled(Link)`
    color: ${({theme}) => theme.text};
    margin: auto 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size: 0.8rem;
    background: ${({theme}) => theme.cardBg};

    &:hover{
        color: ${({theme}) => theme.accent.primary};
    }
`

export const BreadcrumbLink = styled(Link)`
    color: ${({theme}) => theme.accent.primary.color};
    margin-right: 0.4rem;

    &:hover{
        color: ${({theme}) => theme.accent.primary.color};
        filter: saturate(4);
    }
`

export const ProdTitle = styled.h1`
    font-size: ${({theme}) => theme.typo.h1};
    font-weight: 400;
    line-height: 1.8rem;
`

export const MetaTitle = styled.span`
    font-weight: 500;
    opacity: 0.6;
`

export const MetaInfo = styled.span`
    margin-left: 0.5rem;
`

export const MetaInfoLink = styled(GatsbyLink)`
    margin-left: 0.5rem;
    color: ${({theme}) => theme.text};
    
    &:hover{
        color: ${({theme}) => theme.accent.primary.color};
    }
`

export const TagStyled = styled(Link)`
    color: ${({theme}) => theme.text};
    margin: 0.2rem 0.2rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    font-size: 0.8rem;
    background: ${({theme}) => theme.accent.black.elev2};
    white-space: nowrap;

    &:hover{
        background: ${({theme}) => theme.accent.primary.color};
    }
`

export const PPrice = styled.span`
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;

    small{
        font-size: 0.7rem;
        opacity: 0.8;
        line-height: 0.7rem;
    }
`

export const PMrp = styled.span`
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: flex;
    flex-direction: column;

    small{
        font-size: 0.7rem;
        opacity: 0.8;
        line-height: 0.7rem;
    }
`

export const Offer = styled.span`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    padding: 5px 7px;
    background: #40E0D0;
    background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0); 
    background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
    color: black;
    border-radius: 10px;
    user-select: none;
`

export const StockTitle = styled.span`
    opacity: 0.8;
    font-size: 0.9rem;
    margin-right: 5px;
`

export const Stocks = styled.span`
    font-size: 2.4rem;
`

export const AddToCartCon = styled.div`
    display: grid;
    grid-template-columns: min-content max-content;
`

export const ProdDesc = styled.p`
    font-size: 1rem;
    font-weight: 400;
    font-family:${({theme}) => theme.fonts.font2};
`
export const ColHighlight = styled(Col)`
    background: ${({theme}) => theme.accent.black.elev1};
    border-radius: 10px;
`

export const ShareBTN = styled(Button)`
    background-color: ${({theme}) => theme.accent.black.elev2};
    border: 0;

`

export const FormControl = styled(Form.Control)`
    background: ${({theme}) => theme.accent.black.elev2};
    border: 1px solid ${({theme}) => theme.accent.black.elev2};
    color: #ffffff;
    -moz-appearance: textfield;

    &:focus-within{
        background: ${({theme}) => theme.accent.black.elev2};
        color: #ffffff;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
        -webkit-appearance: none; 
    }
`