import React, { useState } from "react"
import { graphql } from "gatsby"
import { Row, Col, FloatingLabel } from 'react-bootstrap'
import isEqual from "lodash.isequal"
import { getSrc } from "gatsby-plugin-image"
// import gql from 'graphql-tag'
// import { useQuery } from 'urql'

import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/buttons/addToCart/add-to-cart"
import { NumericInput } from "../../../components/input/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/helmet/seo"

// import { SearchProvider } from "../../../context/search-provider"


import "swiper/scss/effect-fade"

import { scrollForMore,noImagePreview,optionsWrapper,selectVariant} from "./product-page.module.css"

import {ProductContainer, GalleryContainer, GatsbyImg, GatsbyThumbsImg, TagStyled, BreadcrumbLink, ProdTitle, ProdDesc, MetaTitle, MetaInfoLink, PPrice, PMrp, Offer, StockTitle, Stocks, AddToCartCon, ColHighlight, ShareBTN, FormControl } from '../../../styles/pages/shopifyProductPage.styles'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Thumbs } from 'swiper/core';

import { InlineIcon } from '@iconify/react';

SwiperCore.use([EffectFade, Thumbs]);

export default function Product({ data: { product, suggestions } }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    vendor,
    totalInventory,
    productType,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1
  const lSizeImgContainer = hasMultipleImages ? 5 : 6
  const lSizeAside = hasMultipleImages ? 7 : 6

  const shareURL = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: title,
        url: window.location.href,
      })
        .then(() => console.info('Successful share'))
        .catch((error) => console.error('Error sharing', error));
    }
  }

  return (
    <>
      {firstImage ? ( <Seo title={title} description={description} image={getSrc(firstImage.gatsbyImageData)} /> ) : undefined}
      <ProductContainer fluid itemScope itemType="https://schema.org/Product">

        <Row className={"d-flex justify-content-center mt-5 px-4 g-0"}>
          <Col sm={12}>
            
            <Row className="d-flex justify-content-between">
              <Col sm={12} lg={lSizeImgContainer}>
                {hasImages && (
                <GalleryContainer>
                  {hasMultipleImages && (
                    <div className={scrollForMore} id="instructions">
                      <span aria-hidden="true">←</span> scroll for more{" "}<span aria-hidden="true">→</span>
                    </div>
                  )}
                  <Swiper className="mySwiper2" loop={true} spaceBetween={10} slidesPerView={1} effect={'fade'} thumbs={{ swiper: thumbsSwiper }} role="group" aria-label="gallery" aria-describedby="instructions">
                    {images.map((image, index) => (
                      <SwiperSlide key={`product-image-${image.id}`} >
                        <GatsbyImg objectFit="cover" loading={index === 0 ? "eager" : "lazy"}
                          alt={image.altText? image.altText : `Product Image of ${title} #${index + 1}`}
                          image={image.gatsbyImageData} itemProp="image" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {(images.length > 1) && 
                    <Swiper onSwiper={setThumbsSwiper} slidesPerView={4} freeMode={true} className="mySwiper me-3 d-none d-md-flex SwiperThumbs">
                    {images.map((image, index) => (
                      <SwiperSlide className="mb-1" key={`product-imgThumbs-${image.id}`} >
                        <GatsbyThumbsImg objectFit="cover" loading={index === 0 ? "eager" : "lazy"}
                          alt={image.altText? image.altText : `Product Image thumbs of ${title} #${index + 1}`}
                          image={image.gatsbyImageData} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  }
                  
                  
                </GalleryContainer>
                )}
                {!hasImages && (
                  <span className={noImagePreview}>No Preview image</span>
                )}
              </Col>

              <Col sm={12} lg={lSizeAside} className="mt-4 mt-md-0">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><BreadcrumbLink to={product.productTypeSlug}>{product.productType}</BreadcrumbLink></li>
                  </ol>
                </nav>
                <ProdTitle itemProp="name">{title}</ProdTitle>

                <div className="d-flex flex-row">
                  <div className="me-5">
                    <MetaTitle>Type</MetaTitle>
                    <MetaInfoLink to={product.productTypeSlug}>{productType}</MetaInfoLink>
                  </div>
                  <div>
                    <MetaTitle>Brand</MetaTitle>

                    <MetaInfoLink to={product.vendorTypeSlug}>
                      <span itemProp="brand" itemType="http://schema.org/Brand" content={vendor}>{vendor}</span>
                    </MetaInfoLink>
                  </div>
                </div>
                
                <Row>
                  <Col sm={12} lg={8} itemProp="offers" itemScope itemType="https://schema.org/Offer">
                    <div className="d-flex flex-row align-items-center justify-content-between my-4">
                      <PPrice>
                        <small>PRICE</small>
                        <meta itemProp="priceCurrency" content={priceRangeV2.minVariantPrice.currencyCode} />
                        <meta itemProp="price" content={variant.price} />
                        <span>{price}</span>
                      </PPrice>
                      <PMrp>
                        <small>MRP</small>
                        <span style={{textDecoration: "line-through"}}>{productVariant.compareAtPrice}</span>
                      </PMrp>
                      <Offer>50%<br />Off</Offer>
                    </div>

                    <div className="d-flex flex-row align-items-center mb-3">
                      <StockTitle>Stocks<br />Available</StockTitle>
                      <Stocks><link itemProp="availability" href="https://schema.org/InStock" />{totalInventory}</Stocks>
                      <Stocks></Stocks>
                    </div>

                    <fieldset className={optionsWrapper}>
                      {hasVariants &&
                        options.map(({ id, name, values }, index) => (
                          <div className={selectVariant} key={id}>
                            <select
                              aria-label="Variants"
                              onBlur={(event) => handleOptionChange(index, event)}
                            >
                              <option value="">{`Select ${name}`}</option>
                              {values.map((value) => (
                                <option value={value} key={`${name}-${value}`}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        ))}
                    </fieldset>
                    <AddToCartCon>
                      <NumericInput
                        type="number"
                        aria-label="Quantity"
                        onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                        onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                        onChange={(event) => setQuantity(event.currentTarget.value)}
                        value={quantity}
                        min="1"
                        max={totalInventory}
                        step="1"
                      />
                      <AddToCart className="ms-2 ms-md-4" variantId={productVariant.storefrontId} quantity={quantity} available={available} />
                    </AddToCartCon>
                    <ProdDesc itemProp="description" className="mt-3" dangerouslySetInnerHTML={{__html: descriptionHtml }}></ProdDesc>

                  </Col>
                  <ColHighlight sm={12} lg={4} className="p-4">
                    <div className="d-flex flex-column">
                      <form autoComplete="off">
                        <FloatingLabel style={{fontSize: '12px'}} controlId="floatingInput" label="Check Pincode Availability" className="mb-4">
                          <FormControl type="text" placeholder="600078" autoComplete="off" pattern="[\d]{6}" />
                      </FloatingLabel>
                      </form>
                      
                      <div className="d-flex flex-column mb-3">
                        <MetaTitle className="me-3">SKU</MetaTitle>
                        <span itemProp="sku">{productVariant.sku}</span>
                      </div>
                      {productVariant.barcode &&
                        <div className="d-flex flex-column"> 
                          <MetaTitle className="me-3">EAN / UPC</MetaTitle>
                          <span itemProp="gtin" itemType="https://schema.org/gtin">{productVariant.barcode}</span>
                        </div>
                      }
                      
                      <div className="d-flex flex-column mt-3">
                        <MetaTitle>Tags</MetaTitle>
                        <div className="d-flex flex-wrap">
                          {product.tags.map((tag,i) => (
                            <TagStyled key={`tag${i}`} to={`/search?t=${tag}`}>{tag}</TagStyled>
                          ))}
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <ShareBTN onClick={shareURL}>
                          <InlineIcon icon="ion:share-social" className="me-2" /> Share
                        </ShareBTN>
                      </div>
                    </div>
                  </ColHighlight>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ProductContainer>
    </>
  )
}

export const query = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      vendor
      description
      descriptionHtml
      productType
      totalInventory
      vendorTypeSlug: gatsbyPath(
        filePath: "/vendor/{ShopifyProduct.vendor}"
      )
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 420, aspectRatio: 1)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        compareAtPrice
        sku
        barcode
        weight
        weightUnit
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
