import styled from 'styled-components'
import {Button} from 'react-bootstrap'

export const AddToCartBtn = styled(Button)`
    display: flex;
    flex-direction: row;
    color: ${({theme}) => theme.accent.primary.text};
    background-color: ${({theme}) => theme.accent.primary.color};
    align-self: flex-end;
    padding: 1rem 1rem;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: ${({theme}) => theme.accent.primary.color};
        color: ${({theme}) => theme.accent.primary.text};
        box-shadow: var(--shadow);
    }
`